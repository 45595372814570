<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <AvatarAddAvatar
                :entity="form"
                :file="form.image"
                @mainImageUploaded="mainImageUploaded"
              />
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="type"
                :label="$t('type')"
                @keydown.enter.prevent="submit(ruleFormRef)"
              >
                <ElSelect
                  v-model="form.type"
                  :placeholder="$t('select_a_type')"
                  :autocomplete="true"
                  style="width: 100%"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in membershipTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="months" :label="$t('months')">
                <ElInputNumber v-model="form.months" type="price" min="0" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="price" :label="$t('price')">
                <ElInputNumber
                  v-model="form.price"
                  type="price"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="currency" :label="$t('currency')">
                <ElSelect
                  v-model="form.currency"
                  :autocomplete="true"
                  :filterable="true"
                  style="width: 100%"
                  :placeholder="$t('select_currency')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="personal_travel_concierge"
                :label="$t('personal_travel_concierge')"
              >
                <ElSwitch v-model="form.personal_travel_concierge" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="show"
                :label="$t('show')"
              >
                <ElSwitch v-model="form.show" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                v-if="selectedFieldLanguage"
                prop="description"
                :label="$t('description')"
                class="translation-dialog"
              >
                <ElSelect
                  v-model="selectedFieldLanguage"
                  :autocomplete="true"
                  :placeholder="$t('select_an_option')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <template #prefix>
                    <img
                      v-if="selectedFieldLanguageFlag"
                      :src="selectedFieldLanguageFlag.flag"
                    />
                  </template>
                  <el-option
                    v-for="item in languages"
                    :key="item.iso_code"
                    :label="item.label"
                    :value="item.iso_code"
                  />
                </ElSelect>
                <div class="textarea-container">
                  <ElInput
                    v-model="
                      form[`description_${selectedFieldLanguage.toLowerCase()}`]
                    "
                    :autosize="autosize"
                    type="textarea"
                    maxlength="300"
                    show-word-limit
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  />
                </div>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                v-if="selectedFieldLanguage"
                prop="short_description"
                :label="$t('short_description')"
                class="translation-dialog"
              >
                <ElSelect
                  v-model="selectedFieldLanguage"
                  :autocomplete="true"
                  :placeholder="$t('select_an_option')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <template #prefix>
                    <img
                      v-if="selectedFieldLanguageFlag"
                      :src="selectedFieldLanguageFlag.flag"
                    />
                  </template>
                  <el-option
                    v-for="item in languages"
                    :key="item.iso_code"
                    :label="item.label"
                    :value="item.iso_code"
                  />
                </ElSelect>
                <div class="textarea-container">
                  <ElInput
                    v-model="
                      form[`short_description_${selectedFieldLanguage.toLowerCase()}`]
                    "
                    :autosize="autosize"
                    type="textarea"
                    maxlength="300"
                    show-word-limit
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  />
                </div>
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_MEMBERSHIP from '~/graphql/memberships/mutation/createMembership.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  type: '',
  price: 0,
  currency: 'eur',
  personal_travel_concierge: false,
  description_es: '',
  description_en: '',
  description_fr: '',
  description_de: '',
  description_sv: '',
  description_ar: '',
  short_description_es: '',
  short_description_en: '',
  short_description_fr: '',
  short_description_de: '',
  short_description_sv: '',
  short_description_ar: '',
  months: 0,
  image_id: '',
  show: false,
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const currencies = getCurrencies()
const membershipTypes = getMembershipTypes()
const selectedFieldLanguage = ref('EN')
const languages = getLanguages()

const selectedFieldLanguageFlag = computed(() => {
  return languages.find((item) => item.iso_code === selectedFieldLanguage.value)
})
onMounted(() => {
  setFieldLanguage()
})

const inputRules = reactive({
  type: [
    {
      required: true,
      message: t('type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  months: [
    {
      required: true,
      message: t('months_are_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const rules = {
  type: { required },
  months: { required }
}

const v$ = useVuelidate(rules, form)

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    type: form.type,
    price: form.price ? parseInt(form.price * 100) : 0,
    currency: form.currency,
    months: form.months,
    personal_travel_concierge: form.personal_travel_concierge,
    description_en: form.description_en,
    description_es: form.description_es,
    description_fr: form.description_fr,
    description_de: form.description_de,
    description_sv: form.description_sv,
    description_ar: form.description_ar,
    short_description_en: form.short_description_en,
    short_description_es: form.short_description_es,
    short_description_fr: form.short_description_fr,
    short_description_de: form.short_description_de,
    short_description_sv: form.short_description_sv,
    short_description_ar: form.short_description_ar,
    show: form.show
  }
  if (form.image_id) {
    variables.image_id = form.image_id
  }
  const response = await mutation(CREATE_MEMBERSHIP, variables)
  if (response.error) {
    if (response.error.message.includes('input.type')) {
      $showError(t('membership_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
    loading.value = false
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createMembership ?? null)
    loading.value = false
  }
}

const setFieldLanguage = () => {
  if (getAuthUser()?.value?.language?.toLowerCase() === 'en') {
    selectedFieldLanguage.value = 'EN'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'es') {
    selectedFieldLanguage.value = 'ES'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'fr') {
    selectedFieldLanguage.value = 'FR'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'de') {
    selectedFieldLanguage.value = 'DE'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'sv') {
    selectedFieldLanguage.value = 'SV'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'ar') {
    selectedFieldLanguage.value = 'AR'
  }
}

const mainImageUploaded = (fileVariables) => {
  form.image_id = fileVariables.file
}
</script>

<style>
</style>